<template>
  <div class="qrcodeDetail content">
    <div class="time">二维码有效时间：{{ popupData.time }}</div>
    <img :src="popupData.filecontent" />
    <el-button type="primary" class="btn" @click="download"
      >点击下载二维码图片</el-button
    >
  </div>
</template>
<script>
export default {
  name: "qrcodeDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {
    download() {
      this.downloadFile("二维码.png", this.popupData.filecontent);
    },
    //下载
    downloadFile(fileName, content) {
      let aLink = document.createElement("a");
      let blob = this.base64ToBlob(content);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    }
  }
};
</script>
<style scoped lang="less">
.qrcodeDetail {
  .time {
    margin-left: 15px;
    font-size: 16px;
    color: #f00f00;
  }
  img {
    width: 400px;
    height: 400px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .btn {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 25px;
  }
}
</style>
